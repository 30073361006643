// user.selectors.ts

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { distanceBetween } from 'geofire-common';

import { ArkSearchBarFilter } from '../../../../../../ark/src/public-api';
import { selectUser } from '../auth/selectors';
import { UserState } from './state';

export const selectUserState = createFeatureSelector<UserState>('user');

export const selectUserByUid = (uid: string) =>
  createSelector(
    selectUserState,
    (state: UserState) => state.users[uid],
  );

export const selectUsersByUids = (uids: string[]) =>
  createSelector(
    selectUserState,
    (state: UserState) => uids.map((uid) => state.users[uid]).filter((user) => !!user),
  );


export const selectUsersByLatLngForAuthUser = (
  start = 0,
  end?: number,
  filter?: ArkSearchBarFilter,
) => createSelector(
  selectUser,
  selectFilteredUsers(filter),
  (authUser, selectedUsers) => {
    const users = Object.values(selectedUsers);
    if (!users || !authUser) return [];

    const lat = authUser.defaultCity.lat;
    const lng = authUser.defaultCity.lng;

    const radiusInM = 200 * 1000;
    const center: [number, number] = [lat, lng];
    return users.filter((user) => {
      if (!user?.defaultCity?.lat || !user?.defaultCity?.lng) return false;

      const distanceInKm = distanceBetween(
        [user?.defaultCity?.lat, user?.defaultCity?.lng],
        center,
      );
      const distanceInM = distanceInKm * 1000;
      return distanceInM <= radiusInM;
    }).sort((a, b)=>(b.createdAt.nanoseconds - a.createdAt.nanoseconds)).slice(start, end);
  },
);

export const selectFilteredUsers = (filter: ArkSearchBarFilter) => createSelector(
  selectUserState,
  (userState) => {
    const users = Object.values(userState.users);
    if (!users) return [];
    if ( // If no relevant filters are applied, return all users
      !filter ||
      (!filter.gameType?.length &&
      !filter.levels?.length &&
      !filter.days?.length &&
      !filter.ratings?.length)
    ) return users;

    return users
      .filter((user) =>
        filter.gameType?.length ?
        user.sportAvailability?.some((sport) => filter.gameType.includes(sport.sport)) :
        true,
      )
      .filter((user) =>
        filter.levels?.length ?
        user.sportAvailability?.some((sport) => filter.levels.includes(sport.skill)) :
        true,
      )
      .filter((user) =>
        filter.days?.length ?
        filter.days.every((day) =>
          user.userAvailability?.weeklyAvailability &&
          user.userAvailability.weeklyAvailability[day.toLowerCase()].isAvailable,
        ) :
        true,
      )
      .filter((user) =>
        filter.ratings?.length ?
        user.ratings?.some((rating) => filter.ratings.includes(rating.rating)) :
        true,
      );
  },
);
